import { Box, Typography } from "@mui/material";

import s from "./InfoScreenV1.module.scss";
import { parseVariables } from "../../../../utils/parseStrings";
import ActionsContainer from "../../../ActionsContainer";
import { Progress } from "../../../QuizLayout/components/Progress";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  background_color?: string;
  image?: string;
  text?: string;
  cta?: string;
  screenName?: string;
  progress?: boolean;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    image,
    background_color,
    text,
    cta,
    screenName,
    progress
  } = props;
  console.log("screenName", screenName);
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: background_color,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}></Box>
      {progress && (
        <Box sx={{ padding: "0 12px" }}>
          <Box sx={{ marginBottom: "10px" }} />
          <Progress />
          <Box sx={{ marginBottom: "16px" }} />
        </Box>
      )}

      {image && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "41px",
            zIndex: 10
          }}>
          <img src={image} style={{ width: "50px", height: "50px" }} />
        </Box>
      )}

      <div className={s.content}>
        <Typography
          variant="title_01"
          sx={{
            fontFamily: "SF UI",
            fontSize: "26px",
            fontWeight: "700",
            lineHeight: "31px",
            marginBottom: "30px"
          }}>
          {parseVariables(title) && (
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(title)
              }}></span>
          )}
        </Typography>
        {parseVariables(subtitle) && (
          <Typography
            sx={{
              fontFamily: "SF UI",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.2",
              marginBottom: "56px"
            }}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(subtitle)
              }}></span>
          </Typography>
        )}
        {parseVariables(text) && (
          <Typography
            sx={{
              fontFamily: "SF UI",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.2",
              marginBottom: "56px"
            }}>
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(text)
              }}></span>
          </Typography>
        )}
      </div>
      <ActionsContainer cta={cta} />
    </>
  );
};

export default Component;
