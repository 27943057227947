import { Typography } from "@mui/material";

import { parseVariables } from "../../../../utils/parseStrings";
import { ReviewList } from "../../../ReviewList";
import { ProgressList } from "./ProgressList";
import s from "./CalculationV2.module.css";

interface ComponentProps {
  title?: string;
  items?: { text: string }[];
  reviews?: { text: string; author: string }[];
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, items, reviews, screenName } = props;
  console.log("Screen name", screenName);

  return (
    <>
      <div className={s.container}>
        {parseVariables(title) && (
          <Typography variant="title_01">
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(title)
              }}></span>
          </Typography>
        )}
        {items && (
          <ProgressList
            className={s.progressList}
            list={items.map((i) => ({
              ...i,
              text: parseVariables(i.text)
            }))}
            onLoaded={() => {
              console.log("loaded");
            }}
          />
        )}
        {reviews && (
          <ReviewList
            list={reviews.map((review) => ({ ...review, rating: 5 }))}
          />
        )}
      </div>
    </>
  );
};

export default Component;
