import { Box, Typography } from "@mui/material";
import { parseVariables } from "../../../../utils/parseStrings";
import ActionsContainer from "../../../ActionsContainer";
import { ReviewList } from "../../../ReviewList";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  image?: string;
  cta?: string;
  fill?: boolean;
  reviews?: { review: string; author: string }[];
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    image,
    cta,
    reviews,
    fill = true,
    screenName
  } = props;

  return (
    <Box key={screenName}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 150px)",
          width: "100%"
        }}>
        {parseVariables(title) && (
          <Typography
            variant="title_01"
            align="left"
            dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
            sx={{
              marginTop: "17px",
              marginBottom: parseVariables(subtitle) ? "12px" : "32px"
            }}></Typography>
        )}
        {parseVariables(subtitle) && (
          <Typography
            variant="subtitle_01"
            align="left"
            dangerouslySetInnerHTML={{
              __html: parseVariables(subtitle)
            }}
            sx={{ marginBottom: "20px" }}></Typography>
        )}
        <Box
          sx={{
            width: "100%",
            borderRadius: "36px",
            overflow: "hidden",
            flexGrow: "1",
            minHeight: "325px",
            backgroundImage: "url(" + image + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: fill ? "cover" : "contain",
            backgroundPosition: fill ? "center" : "top center"
          }}></Box>

        {reviews && (
          <ReviewList
            list={reviews.map((review) => ({
              ...review,
              text: review.review,
              rating: 5
            }))}
          />
        )}
      </Box>

      <ActionsContainer cta={cta} />
    </Box>
  );
};

export default Component;
