import { parseVariables } from "../../../../utils/parseStrings";
import { ScaleField } from "./ScaleField";
import { ScaleNotation } from "../../../../utils/useScaleNotationValue";
import { Progress } from "../../../QuizLayout/components/Progress";
import { Box, Button, Typography } from "@mui/material";
import Highlight from "../../../Highlight";
import { useEffect, useState } from "react";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  options?: { text: string; image: string }[];
  cta?: string;
  screenName?: string;
  gray_hint?: string;
  blue_hint?: string;
  green_hint?: string;
  green_hint_2?: string;
  green_hint_3?: string;
  green_hint_4?: string;
  orange_hint?: string;
  red_hint?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    screenName,
    cta,
    gray_hint,
    blue_hint,
    green_hint,
    orange_hint,
    green_hint_2,
    green_hint_3,
    green_hint_4,
    red_hint
  } = props;
  const [hintParsed, setHintParsed] = useState<string | undefined>(gray_hint);
  const [hintClass, setHintClass] = useState<string>("gray");

  useEffect(() => {
    setHintClass("red");
    setHintParsed(red_hint);
  }, [red_hint]);

  useEffect(() => {
    setHintClass("orange");
    setHintParsed(orange_hint);
  }, [orange_hint]);

  useEffect(() => {
    setHintClass("green");
    setHintParsed(green_hint);
  }, [green_hint]);

  useEffect(() => {
    setHintClass("gray");
    setHintParsed(gray_hint);
  }, [gray_hint]);

  useEffect(() => {
    setHintClass("blue");
    setHintParsed(blue_hint);
  }, [blue_hint]);

  useEffect(() => {
    setHintClass("green");
    setHintParsed(green_hint_2);
  }, [green_hint_2]);

  useEffect(() => {
    setHintClass("green");
    setHintParsed(green_hint_3);
  }, [green_hint_3]);

  useEffect(() => {
    setHintClass("green");
    setHintParsed(green_hint_4);
  }, [green_hint_4]);

  return (
    <div id="inputContainer" key={screenName}>
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="title_01"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginBottom: "12px" }}></Typography>
      )}

      <Box>
        <ScaleField
          scales={[
            { value: ScaleNotation.imperial, label: "lbs" },
            { value: ScaleNotation.metric, label: "kg" }
          ]}
          variant="big"
          value={0}
          scaleNotation={ScaleNotation.imperial}
        />
      </Box>

      <Highlight className={hintClass}>
        <>
          {parseVariables(hintParsed) && (
            <span
              dangerouslySetInnerHTML={{
                __html: parseVariables(hintParsed)
              }}></span>
          )}
        </>
      </Highlight>

      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginTop: "24px" }}>
        {cta}
      </Button>
    </div>
  );
};

export default Component;
