import { clsx } from "clsx";
import s from "./ScaleField.module.css";
import { useEffect, useRef, useState } from "react";
import {
  ScaleNotation,
  UseScaleNotationValueResult
} from "../../../../../utils/useScaleNotationValue";
import { TextField } from "@mui/material";

export type ScaleFieldProps = UseScaleNotationValueResult & {
  scales: { value: ScaleNotation; label: string }[];
} & {
  scaleNotation: ScaleNotation;
  handleChangeScaleNotation?: (scaleNotation: ScaleNotation) => void;
};

export const ScaleField = ({
  handleChangeScaleNotation,
  scales,
  scaleNotation,
  value
}: ScaleFieldProps) => {
  const feetRef = useRef<HTMLInputElement>(null);
  const inchRef = useRef<HTMLInputElement>(null);

  const getInchesPart = (value: number) => {
    const inches = value / 2.54;
    // const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    return remainingInches ? remainingInches : 0;
  };

  const getFeetsPart = (value: number) => {
    const inches = value / 2.54;
    const feet = Math.floor(inches / 12);
    return feet ? feet : 0;
  };

  const [feets] = useState(getFeetsPart(value));
  const [inches] = useState(getInchesPart(value));
  console.log("Metric: ", value);
  console.log("Imperial: ", feets, inches);
  useEffect(() => {
    // focus the firs iput initially
    if (feetRef?.current) {
      (feetRef.current as any).focus();
    }
  }, [feetRef, inchRef]);

  return (
    <div className={s.container}>
      {scaleNotation === ScaleNotation.imperial && (
        <div className={s.inputs}>
          <TextField
            id="heightFt"
            // ref={feetRef}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "0",
              input: {
                fontSize: "28px",
                textAlign: "center"
              },
              fieldset: {
                border: "none",
                borderRadius: "0",
                borderBottom: "1px solid rgba(249, 249, 249, 0.94)"
              }
            }}
            autoFocus={false}
            // variant="primary2-big"
            className={clsx(s.input, s.inputBig)}
            type="tel"
            // pattern="[0-9]*"
            // step={0.1}
            // onChange={handleImperialChangeFeets}
            value={feets + ""}
            // noteText={noteText}
            placeholder="0"

            // endAdornment={<h5>ft</h5>}
          />
          <h5>ft</h5>
          <TextField
            id="heightIn"
            // ref={inchRef}
            // variant="primary2-big"
            className={clsx(s.input, s.inputBig)}
            type="tel"
            sx={{
              backgroundColor: "transparent",
              borderRadius: "0",
              input: {
                fontSize: "28px",
                textAlign: "center"
              },
              fieldset: {
                border: "none",
                borderRadius: "0",
                borderBottom: "1px solid rgba(249, 249, 249, 0.94)"
              }
            }}
            // pattern="[0-9]*"
            // step={0.1}
            // onChange={handleImperialChangeInches}
            value={inches + ""}
            placeholder="0"
            // noteText={noteText}
            // endAdornment={<h5>in</h5>}
          />
          <h5>in</h5>
        </div>
      )}
      {scaleNotation === ScaleNotation.metric && (
        <div className={s.inputs}>
          <TextField
            autoFocus={false}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "0",
              input: {
                fontSize: "28px",
                textAlign: "center"
              },
              fieldset: {
                border: "none",
                borderRadius: "0",
                borderBottom: "1px solid rgba(249, 249, 249, 0.94)"
              }
            }}
            // variant="primary2-big"
            className={clsx(s.inputLarge, s.inputBig)}
            type="tel"
            // pattern="[0-9]*"
            // step={1}
            // onChange={handleChange}
            value={String(value === 0 ? "" : value)}
            placeholder="0"
            // noteText={noteText}
            // endAdornment={<h5>cm</h5>}
          />
        </div>
      )}
      <div className={s.scales}>
        {scales.map((scale) => (
          <button
            key={scale.value}
            className={clsx(
              s.scaleButton,
              scale.value === scaleNotation && s.scaleButtonActive
            )}
            type="button"
            onClick={() => handleChangeScaleNotation?.(scale.value)}>
            {scale.label}
          </button>
        ))}
      </div>
    </div>
  );
};
