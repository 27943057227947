import React from "react";
import { Box, MenuItem, Paper, Select, TextField } from "@mui/material";

interface Benefit {
  text: string;
  emoji: string;
  layout: string;
}

interface ReviewsBlockProps {
  parameters: {
    title?: string;
    items: Benefit[];
    layout: string;
  };
  onChange: (param: string, value: unknown) => void;
}

const ReviewsBlock: React.FC<ReviewsBlockProps> = ({
  parameters,
  onChange
}) => {
  const reivewLayoutVariants = [
    {
      value: "default",
      label: "◻️ Default: LABEL and list ofemoji, text & checkmark"
    },
    {
      value: "colored",
      label: "🟧 Colored: email, LABEL and list of text & checkmark"
    }
  ];

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        Layout
        <Select
          value={parameters.layout || "default"}
          onChange={(e) => onChange("layout", e.target.value)}
          sx={{ height: 48 }}>
          {reivewLayoutVariants.map((layout) => (
            <MenuItem key={layout.value} value={layout.value}>
              {layout.label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Title"
          value={parameters.title || ""}
          onChange={(e) => onChange("title", e.target.value)}
        />
        {parameters.items.map((benefit, benefitIndex) => (
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              border: "1px solid #ccc",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
            <TextField
              label="Benefit Text"
              value={benefit.text}
              onChange={(e) => {
                const updatedBenefits = [...parameters.items];
                updatedBenefits[benefitIndex].text = e.target.value;
                onChange("items", updatedBenefits);
              }}
            />
            <TextField
              label="Emoji"
              value={benefit.emoji}
              onChange={(e) => {
                const updatedBenefits = [...parameters.items];
                updatedBenefits[benefitIndex].emoji = e.target.value;
                onChange("items", updatedBenefits);
              }}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ReviewsBlock;
