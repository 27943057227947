import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { nanoid } from "nanoid";

import { Paywall, Project } from "../../types";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch
} from "@mui/material";
import {
  ChevronLeft,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Close as CloseIcon
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProviderSelect from "../../components/ProviderSelect/ProviderSelect";

import TimerBlock, { TimerBlockProps } from "./blocks/TimerBlock";
import ImageBlock, { ImageBlockProps } from "./blocks/ImageBlock";
import PlansBlock, { PlansBlockProps } from "./blocks/PlansBlock";
import ReviewsBlock, { ReviewsBlockProps } from "./blocks/Reviews";
import BenefitsBlock from "./blocks/Benefits";
import PaywallPreview from "../../components/PaywallPreview/PaywallPreview";
import StripePriceDropdown, {
  StripePrice
} from "../../components/StripePriceDropdown";
import StripeCouponDropdown, {
  StripeCoupon
} from "../../components/StripeCouponDropdown";
import NoteBlock, { NoteBlockProps } from "./blocks/Note";
import StripeInlineCheckout, {
  StripeInlineCheckoutProps
} from "./blocks/StripeInlineCheckout";

import PayProProductDropdown, {
  PayProProduct
} from "../../components/PayProProductDropdown";

// interface BaseBlockProps {
//   parameters: BlockParameters;
//   projectId?: string;
//   onChange: (param: string, value: unknown) => void;
// }

type BlockComponent = React.ComponentType<
  | TimerBlockProps
  | ImageBlockProps
  | PlansBlockProps
  | ReviewsBlockProps
  | NoteBlockProps
  | StripeInlineCheckoutProps
>;

const blockTypes: Array<{
  type: string;
  component: BlockComponent;
}> = [
  { type: "Timer", component: TimerBlock as BlockComponent },
  { type: "Image", component: ImageBlock as BlockComponent },
  { type: "Plans", component: PlansBlock as BlockComponent },
  { type: "Reviews", component: ReviewsBlock as BlockComponent },
  { type: "Benefits", component: BenefitsBlock as BlockComponent },
  { type: "Note", component: NoteBlock as BlockComponent },
  {
    type: "StripeInlineCheckout",
    component: StripeInlineCheckout as BlockComponent
  }
];

export interface BlockParameters {
  [key: string]: unknown;
}

interface UpsellConfig {
  enabled: boolean;
  provider: "stripe" | "paypro" | "stripe+paypro";
  stripe?: {
    price?: StripePrice;
    normalDiscount?: StripeCoupon;
    upsellDiscount?: StripeCoupon;
  };
  paypro?: {
    product?: PayProProduct;
    normalDiscount?: string;
    upsellDiscount?: string;
    pageTemplate?: string;
  };
}

interface Block {
  id: string;
  type: string;
  parameters: BlockParameters;
  component: BlockComponent | null;
}

const PaywallPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId, paywallId } = params;
  const [user, setUser] = useState<User | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedBlockType, setSelectedBlockType] = useState("");
  const [normalBlocks, setNormalBlocks] = useState<Block[]>([]);
  const [remarketBlocks, setRemarketBlocks] = useState<Block[]>([]);
  const [activeTab, setActiveTab] = useState("default_paywall");
  // const [useOldConfig, setUseOldConfig] = useState(false);
  // const [oldConfigJson, setOldConfigJson] = useState("");
  const [blockToDelete, setBlockToDelete] = useState<{
    index: number;
    type: string;
  } | null>(null);

  const [upsellConfig, setUpsellConfig] = useState<UpsellConfig>({
    enabled: false,
    provider: "stripe",
    stripe: {
      price: undefined,
      normalDiscount: undefined,
      upsellDiscount: undefined
    },
    paypro: {
      product: undefined,
      normalDiscount: "",
      upsellDiscount: "",
      pageTemplate: ""
    }
  });

  console.log("Project", project, loading);
  const syncPaywall = (user: User, projectId: string, paywallId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const paywallRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "paywalls",
        paywallId
      );
      onSnapshot(paywallRef, (snapshot) => {
        const paywall = { id: snapshot.id, ...snapshot.data() };
        const { parameters } = paywall as Paywall;
        console.log("Paywall", paywall);
        initializeBlocks(parameters || {});
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId && paywallId) {
        setUser(user);
        syncProject(user, projectId);
        syncPaywall(user, projectId, paywallId);
      }
    });
  }, [user]);

  const initializeBlocks = (parameters: {
    blocks?: {
      default?: Array<{
        id?: string;
        type: string;
        parameters: BlockParameters;
      }>;
      remarket?: Array<{
        id?: string;
        type: string;
        parameters: BlockParameters;
      }>;
    };
    upsell?: {
      enabled: boolean;
      provider: "stripe" | "paypro" | "stripe+paypro";
      stripe?: {
        price?: StripePrice;
        normalDiscount?: StripeCoupon;
        upsellDiscount?: StripeCoupon;
      };
      paypro?: {
        product?: PayProProduct;
        normalDiscount?: string;
        upsellDiscount?: string;
        pageTemplate?: string;
      };
    } | null;
  }) => {
    if (parameters?.blocks) {
      if (parameters.blocks.default) {
        const normalBlocks = parameters.blocks.default.map((block) => {
          const blockType = blockTypes.find((b) => b.type === block.type);
          return {
            id: block.id || nanoid(),
            type: block.type,
            parameters: block.parameters,
            component: blockType?.component || null
          } as Block;
        });
        setNormalBlocks(normalBlocks);
      }

      if (parameters.blocks.remarket) {
        const remarketBlocks = parameters.blocks.remarket.map((block) => {
          const blockType = blockTypes.find((b) => b.type === block.type);
          return {
            id: block.id || nanoid(),
            type: block.type,
            parameters: block.parameters,
            component: blockType?.component || null
          } as Block;
        });
        setRemarketBlocks(remarketBlocks);
      }
    }

    setUpsellConfig({
      enabled: parameters?.upsell ? true : false,
      provider: parameters?.upsell?.provider || "stripe",
      stripe: parameters?.upsell?.stripe || {
        price: undefined,
        normalDiscount: undefined,
        upsellDiscount: undefined
      },
      paypro: parameters?.upsell?.paypro || {
        product: undefined,
        normalDiscount: "",
        upsellDiscount: "",
        pageTemplate: ""
      }
    });
  };

  const handleAddBlock = () => {
    const block = blockTypes.find((b) => b.type === selectedBlockType);
    if (block) {
      // Find existing Plans block in the current tab to copy parameters from
      const existingPlansBlock =
        activeTab === "default_paywall"
          ? normalBlocks.find((b) => b.type === "Plans")
          : remarketBlocks.find((b) => b.type === "Plans");

      const newBlock: Block = {
        id: nanoid(),
        type: block.type,
        parameters:
          block.type === "Plans" && existingPlansBlock
            ? { ...existingPlansBlock.parameters }
            : {},
        component: block.component
      };

      if (activeTab === "default_paywall") {
        setNormalBlocks([...normalBlocks, newBlock]);
      } else {
        setRemarketBlocks([...remarketBlocks, newBlock]);
      }
    }
  };

  const handleParameterChange = (
    index: number,
    param: string,
    value: unknown
  ) => {
    if (activeTab === "default_paywall" || activeTab === "remarket_paywall") {
      const blocks =
        activeTab === "default_paywall" ? normalBlocks : remarketBlocks;

      // If this is a Plans block, sync the changes to all Plans blocks within the same tab
      if (blocks[index].type === "Plans") {
        const updatedBlocks = blocks.map((block) => {
          if (block.type === "Plans") {
            return {
              ...block,
              parameters: {
                ...block.parameters,
                [param]: value
              }
            };
          }
          return block;
        });

        if (activeTab === "default_paywall") {
          setNormalBlocks(updatedBlocks);
        } else {
          setRemarketBlocks(updatedBlocks);
        }
      } else {
        // Handle other block types normally
        if (activeTab === "default_paywall") {
          const updatedBlocks = [...normalBlocks];
          updatedBlocks[index].parameters[param] = value;
          setNormalBlocks(updatedBlocks);
        } else {
          const updatedBlocks = [...remarketBlocks];
          updatedBlocks[index].parameters[param] = value;
          setRemarketBlocks(updatedBlocks);
        }
      }
    }
  };

  const handleSave = async () => {
    if (user && projectId && paywallId) {
      const db = getFirestore();
      try {
        setLoading(true);

        // Find and sync Plans blocks within their tabs
        const firstNormalPlansBlock = normalBlocks.find(
          (block) => block.type === "Plans"
        );
        const firstRemarketPlansBlock = remarketBlocks.find(
          (block) => block.type === "Plans"
        );

        let normalPlansIndex = 0;
        const syncedNormalBlocks = normalBlocks.map((block) => {
          if (block.type === "Plans" && firstNormalPlansBlock) {
            const updatedBlock = {
              ...firstNormalPlansBlock,
              parameters: {
                ...firstNormalPlansBlock.parameters,
                index: normalPlansIndex++
              }
            };
            return updatedBlock;
          }
          return block;
        });

        let remarketPlansIndex = 0;
        const syncedRemarketBlocks = remarketBlocks.map((block) => {
          if (block.type === "Plans" && firstRemarketPlansBlock) {
            const updatedBlock = {
              ...firstRemarketPlansBlock,
              parameters: {
                ...firstRemarketPlansBlock.parameters,
                index: remarketPlansIndex++
              }
            };
            return updatedBlock;
          }
          return block;
        });

        // Clean up function to remove undefined values
        const cleanObject = (obj: any): any => {
          if (Array.isArray(obj)) {
            return obj.map(cleanObject).filter((item) => item !== undefined);
          }
          if (obj && typeof obj === "object") {
            const cleaned: any = {};
            for (const key in obj) {
              const value = cleanObject(obj[key]);
              if (value !== undefined) {
                cleaned[key] = value;
              }
            }
            return Object.keys(cleaned).length ? cleaned : undefined;
          }
          return obj === undefined ? null : obj;
        };

        // Clean up blocks before saving
        const cleanedNormalBlocks = syncedNormalBlocks.map(
          ({ component: _component, ...rest }) => cleanObject(rest)
        );
        const cleanedRemarketBlocks = syncedRemarketBlocks.map(
          ({ component: _component, ...rest }) => cleanObject(rest)
        );

        const output = {
          blocks: {
            default: cleanedNormalBlocks,
            remarket: cleanedRemarketBlocks
          },
          upsell: upsellConfig.enabled
            ? cleanObject({
                provider: upsellConfig.provider,
                ...(upsellConfig.provider.includes("stripe")
                  ? {
                      stripe: {
                        price: upsellConfig.stripe?.price,
                        ...(upsellConfig.stripe?.normalDiscount && {
                          normalDiscount: upsellConfig.stripe.normalDiscount
                        }),
                        ...(upsellConfig.stripe?.upsellDiscount && {
                          upsellDiscount: upsellConfig.stripe.upsellDiscount
                        })
                      }
                    }
                  : {}),
                ...(upsellConfig.provider.includes("paypro")
                  ? {
                      paypro: {
                        product: upsellConfig.paypro?.product,
                        ...(upsellConfig.paypro?.normalDiscount && {
                          normalDiscount: upsellConfig.paypro.normalDiscount
                        }),
                        ...(upsellConfig.paypro?.upsellDiscount && {
                          upsellDiscount: upsellConfig.paypro.upsellDiscount
                        }),
                        ...(upsellConfig.paypro?.pageTemplate && {
                          pageTemplate: upsellConfig.paypro.pageTemplate
                        })
                      }
                    }
                  : {})
              })
            : null
        };

        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls",
            paywallId
          ),
          {
            parameters: cleanObject(output)
          }
        );
        enqueueSnackbar("Paywall configuration saved!", { variant: "success" });
      } catch (error) {
        console.error("Error saving paywall configuration:", error);
        enqueueSnackbar("Error saving paywall configuration", {
          variant: "error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const renderPreview = () => {
    const blocks =
      activeTab === "default_paywall" ? normalBlocks : remarketBlocks;
    return <PaywallPreview blocks={blocks} />;
  };

  // const handleConfigToggle = () => {
  //   setUseOldConfig(!useOldConfig);
  //   if (!useOldConfig) {
  //     // Switching to old config, convert current blocks to JSON
  //     const currentConfig = {
  //       blocks: {
  //         default: normalBlocks.map(
  //           ({ component: _component, ...rest }) => rest
  //         ),
  //         remarket: remarketBlocks.map(
  //           ({ component: _component, ...rest }) => rest
  //         ),
  //       },
  //     };
  //     setOldConfigJson(JSON.stringify(currentConfig, null, 2));
  //   } else {
  //     // Switching to new config, parse JSON and update blocks
  //     try {
  //       const parsedConfig = JSON.parse(oldConfigJson);
  //       initializeBlocks(parsedConfig);
  //     } catch (error) {
  //       console.error("Error parsing JSON:", error);
  //       enqueueSnackbar("Error parsing JSON configuration", {
  //         variant: "error",
  //       });
  //     }
  //   }
  // };

  const handleMoveBlock = (index: number, direction: "up" | "down") => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    const blocks =
      activeTab === "default_paywall" ? [...normalBlocks] : [...remarketBlocks];

    if (newIndex < 0 || newIndex >= blocks.length) return;

    const [movedBlock] = blocks.splice(index, 1);
    blocks.splice(newIndex, 0, movedBlock);

    if (activeTab === "default_paywall") {
      setNormalBlocks(blocks);
    } else {
      setRemarketBlocks(blocks);
    }
  };

  const handleRemoveBlock = (index: number) => {
    const blocks =
      activeTab === "default_paywall" ? [...normalBlocks] : [...remarketBlocks];
    blocks.splice(index, 1);

    if (activeTab === "default_paywall") {
      setNormalBlocks(blocks);
    } else {
      setRemarketBlocks(blocks);
    }
    setBlockToDelete(null);
  };

  const handleProviderChange = (
    provider: "stripe" | "paypro" | "stripe+paypro"
  ) => {
    setUpsellConfig((prev) => ({
      ...prev,
      provider
    }));
  };

  const blocks =
    activeTab === "default_paywall" ? normalBlocks : remarketBlocks;

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          backgroundColor: "rgba(217, 217, 217, 0.25)",
          padding: "48px",
          flexGrow: "auto",
          minHeight: "calc(100vh - 90px)",
          overflowY: "auto"
        }}>
        <Typography variant="h1">
          <Box component="span" sx={{ cursor: "pointer" }}>
            <IconButton
              sx={{ marginLeft: "-40px" }}
              onClick={() => navigate("./../../")}>
              <ChevronLeft />
            </IconButton>
            Edit data
          </Box>
        </Typography>

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}>
          <Tab
            id="default_paywall"
            label="Default Paywall"
            value="default_paywall"
          />
          <Tab
            id="remarket_paywall"
            label="Remarket Paywall"
            value="remarket_paywall"
          />
          <Tab
            id="upsell_config"
            label="Upsell Configuration"
            value="upsell_config"
          />
        </Tabs>

        {activeTab === "upsell_config" && (
          // Upsell Configuration Tab Content
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={upsellConfig.enabled}
                  onChange={(e) =>
                    setUpsellConfig((prev) => ({
                      ...prev,
                      enabled: e.target.checked
                    }))
                  }
                />
              }
              label="Enable Upsell"
            />

            {upsellConfig.enabled && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Provider
                </Typography>
                <ProviderSelect
                  value={upsellConfig.provider}
                  onChange={handleProviderChange}
                  sx={{ width: "100%" }}
                />

                {(upsellConfig.provider === "stripe" ||
                  upsellConfig.provider === "stripe+paypro") && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Stripe Configuration
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                      }}>
                      <StripePriceDropdown
                        value={upsellConfig.stripe?.price?.id || ""}
                        onChange={(price) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            stripe: {
                              ...prev.stripe!,
                              price: price
                            }
                          }))
                        }
                        projectId={projectId || ""}
                      />
                      <StripeCouponDropdown
                        label="Coupon Default"
                        value={upsellConfig.stripe?.normalDiscount?.id || ""}
                        onChange={(coupon) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            stripe: {
                              ...prev.stripe!,
                              normalDiscount: coupon
                            }
                          }))
                        }
                        projectId={projectId || ""}
                      />
                      <StripeCouponDropdown
                        label="Coupon Remarket"
                        value={upsellConfig.stripe?.upsellDiscount?.id || ""}
                        onChange={(coupon) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            stripe: {
                              ...prev.stripe!,
                              upsellDiscount: coupon
                            }
                          }))
                        }
                        projectId={projectId || ""}
                      />
                    </Box>
                  </Box>
                )}

                {(upsellConfig.provider === "paypro" ||
                  upsellConfig.provider === "stripe+paypro") && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      PayPro Configuration
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                      }}>
                      <PayProProductDropdown
                        value={upsellConfig.paypro?.product?.id || 0}
                        onChange={(product) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            paypro: {
                              ...prev.paypro!,
                              product: product
                            }
                          }))
                        }
                        projectId={projectId || ""}
                      />
                      <TextField
                        label="Page Template"
                        value={upsellConfig.paypro?.pageTemplate || ""}
                        onChange={(e) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            paypro: {
                              ...prev.paypro!,
                              pageTemplate: e.target.value
                            }
                          }))
                        }
                      />
                      <TextField
                        label="Coupon Default"
                        value={upsellConfig.paypro?.normalDiscount || ""}
                        onChange={(e) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            paypro: {
                              ...prev.paypro!,
                              normalDiscount: e.target.value
                            }
                          }))
                        }
                      />
                      <TextField
                        label="Coupon Remarket"
                        value={upsellConfig.paypro?.upsellDiscount || ""}
                        onChange={(e) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            paypro: {
                              ...prev.paypro!,
                              upsellDiscount: e.target.value
                            }
                          }))
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}

        {(activeTab === "default_paywall" ||
          activeTab === "remarket_paywall") && (
          <Box id={activeTab} sx={{ mt: 2 }}>
            <Select
              value={selectedBlockType}
              onChange={(e) => setSelectedBlockType(e.target.value as string)}>
              {blockTypes.map((block) => (
                <MenuItem key={block.type} value={block.type}>
                  {block.type}
                </MenuItem>
              ))}
            </Select>
            <Button onClick={handleAddBlock}>Add Block</Button>

            <Box id={`${activeTab}-blocks`} sx={{ mt: 2 }}>
              {blocks.map((block, index) => (
                <Box
                  key={`${activeTab}-${block.id}-${index}-${block.type}`}
                  sx={{ mb: 2 }}>
                  <Accordion
                    id={`${activeTab}-${block.id}-${index}-${block.type}-accordion`}>
                    <AccordionSummary
                      id={`${activeTab}-${block.id}-${index}-${block.type}-summary`}
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mr: 1
                        }
                      }}>
                      <Typography>{block.type}</Typography>
                      {block.type === "Image" &&
                        typeof block.parameters.image === "string" && (
                          <img
                            src={block.parameters.image}
                            alt="Preview"
                            style={{
                              width: "auto",
                              height: "50px",
                              marginLeft: "10px"
                            }}
                          />
                        )}
                      {block.type === "Plans" &&
                        typeof block.parameters.paywallButtonsLayout ===
                          "string" && (
                          <Typography color="text.secondary">
                            Layout: {block.parameters.paywallButtonsLayout}
                          </Typography>
                        )}
                      <Box
                        id={`${activeTab}-${block.id}-${index}-${block.type}-summary-actions`}
                        sx={{ display: "flex", gap: 0.5 }}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMoveBlock(index, "up");
                          }}
                          disabled={index === 0}
                          sx={{ p: 0.5 }}>
                          <KeyboardArrowUp fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMoveBlock(index, "down");
                          }}
                          disabled={index === blocks.length - 1}
                          sx={{ p: 0.5 }}>
                          <KeyboardArrowDown fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setBlockToDelete({ index, type: block.type });
                          }}
                          sx={{
                            p: 0.5,
                            "&:hover": {
                              color: "error.main"
                            }
                          }}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      id={`${activeTab}-${block.id}-${index}-${block.type}-details`}>
                      {block.component && projectId && (
                        <block.component
                          parameters={{
                            ...block.parameters,
                            NEXT_PUBLIC_STRIPE_KEY:
                              project?.parameters?.NEXT_PUBLIC_STRIPE_KEY
                          }}
                          projectId={projectId}
                          onChange={(param: string, value: unknown) =>
                            handleParameterChange(index, param, value)
                          }
                          isFirstBlock={
                            block.type === "Plans" &&
                            index ===
                              blocks.findIndex((b) => b.type === "Plans")
                          }
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Button onClick={handleSave} disabled={loading} sx={{ mt: 4 }}>
          {loading ? "Saving..." : "Save Configuration"}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: "1",
          backgroundColor: "#fff",
          overflowY: "auto",
          justifyContent: "center",
          alignItems: "flex-start"
        }}>
        {renderPreview()}
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={blockToDelete !== null}
        onClose={() => setBlockToDelete(null)}
        aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">
          Remove {blockToDelete?.type} Block
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this block? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBlockToDelete(null)}>Cancel</Button>
          <Button
            onClick={() =>
              blockToDelete && handleRemoveBlock(blockToDelete.index)
            }
            color="error"
            autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PaywallPage;
