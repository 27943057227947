import { parseVariables } from "../../../../utils/parseStrings";
import {
  QuizOptionGroup,
  useQuizOptionGroup
} from "../../../QuizLayout/components/QuizOptionGroup";

import { Box, Typography } from "@mui/material";
import { Progress } from "../../../QuizLayout/components/Progress";

interface ComponentProps {
  screenName: string;
  title?: string;
  subtitle?: string;
  multichoice?: string;
  cta?: string;
  continueButtonEnabled?: boolean;
  notOption?: string;
  yesOption?: string;
}

const Component = (props: ComponentProps) => {
  const {
    screenName,
    title,
    subtitle,
    cta,
    continueButtonEnabled = false,
    notOption = "Not at all",
    yesOption = "Totally"
  } = props;
  const options = [
    { text: "1", value: "1" },
    { text: "2", value: "2" },
    { text: "3", value: "3" },
    { text: "4", value: "4" },
    { text: "5", value: "5" }
  ];
  const multiSelect = false;
  const parameterName = screenName.toLowerCase().replace(/ /g, "_");
  const quizOptionGroup = useQuizOptionGroup(parameterName, multiSelect);

  const handleSelectOption = (value: string) => {
    quizOptionGroup.selectOption(value);
    if (!continueButtonEnabled && !multiSelect) {
    }
  };
  console.log("Cta", cta);
  return (
    <>
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="title_01"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{
            marginBottom: subtitle ? "12px" : "48px"
          }}></Typography>
      )}
      {subtitle && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle)
          }}
          sx={{ marginBottom: "48px" }}
          align="center"></Typography>
      )}
      <Box>
        {options && options.length > 0 && (
          <QuizOptionGroup
            horizontal
            options={options}
            {...quizOptionGroup}
            selectOption={handleSelectOption}
            multiSelect={multiSelect}
            notOption={notOption}
            yesOption={yesOption}
          />
        )}
      </Box>
    </>
  );
};

export default Component;
