import { parseVariables } from "../../../../utils/parseStrings";
import { Box, Typography } from "@mui/material";
import ActionsContainer from "../../../ActionsContainer";
import { CSSProperties } from "react";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  image?: string;
  cta?: string;
  fill?: boolean;
  switchImage?: boolean;
  roundBorders?: boolean;
  screenName?: string;
  variableDependency?: boolean;
  variableDepenendendImage?: string;
  variableValue?: string;
  variableName?: string;
  topColor?: string;
  bottomColor?: string;
  titleVariant?: string;
  subtitleVariant?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    image,
    cta,
    fill = true,
    switchImage = false,
    roundBorders = true,
    screenName,
    variableDependency,
    variableDepenendendImage,
    variableName,
    topColor,
    bottomColor,
    titleVariant = "title_01",
    subtitleVariant = "subtitle_01"
  } = props;

  const backgroundStyle: CSSProperties | undefined =
    topColor && topColor !== "" && bottomColor && bottomColor !== ""
      ? {
          background: `linear-gradient(${topColor}, ${bottomColor})`,
          // minHeight: "100vh",
          // position: "absolute",
          overflow: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          // zIndex: -1,
          margin: 0,
          padding: 0
        }
      : undefined;

  let imageToUse = image;
  if (variableDependency && variableName === "gender") {
    imageToUse = variableDepenendendImage;
  }

  return (
    <Box key={screenName}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 150px)",
          width: "100%",
          ...backgroundStyle
        }}>
        {switchImage === true && (
          <Box
            sx={{
              width: "100%",
              borderRadius: roundBorders ? "36px" : "0px",
              overflow: "hidden",
              flexGrow: "1",
              minHeight: "325px",
              backgroundImage: "url(" + imageToUse + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: fill ? "cover" : "contain",
              backgroundPosition: fill ? "center" : "top center",
              marginBottom: "24px"
            }}></Box>
        )}
        {parseVariables(title) && (
          <Typography
            variant={titleVariant as any}
            align="left"
            dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
            sx={{
              marginTop: switchImage ? "0" : "17px",
              marginBottom: parseVariables(subtitle) ? "12px" : "32px"
            }}></Typography>
        )}
        {parseVariables(subtitle) && (
          <Typography
            variant={subtitleVariant as any}
            align="left"
            dangerouslySetInnerHTML={{
              __html: parseVariables(subtitle)
            }}
            sx={{ marginBottom: "20px" }}></Typography>
        )}
        {switchImage === false && (
          <Box
            sx={{
              width: "100%",
              borderRadius: roundBorders ? "36px" : "0px",
              overflow: "hidden",
              flexGrow: "1",
              minHeight: "325px",
              backgroundImage: "url(" + imageToUse + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: fill ? "cover" : "contain",
              backgroundPosition: fill ? "center" : "top center"
            }}></Box>
        )}
      </Box>
      <ActionsContainer cta={cta} />
    </Box>
  );
};

export default Component;
