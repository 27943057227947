import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  onSnapshot,
  doc,
  updateDoc,
  collection,
  query,
  orderBy,
  getDocs,
  getDoc,
  setDoc
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable
  // deleteObject,
} from "firebase/storage";

import {
  Funnel,
  FunnelScreen,
  Project,
  Screen,
  ScreenParameter
} from "../../types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import ScreenPreview from "../../components/ScreenPreview/ScreenPreview";
import {
  Add,
  ArrowCircleLeft,
  ArrowCircleRight,
  ChevronLeft,
  Delete,
  DeleteOutline,
  MoreHoriz
} from "@mui/icons-material";
import { nanoid } from "nanoid";

const ScreenPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const notistack = useSnackbar();
  const { projectId, funnelId, screenId } = params;
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [funnel, setFunnel] = useState<Funnel>();
  const [screen, setScreen] = useState<FunnelScreen>();
  const [loading, setLoading] = useState(false);
  console.log("Project", project, funnel, loading);
  const collectParameters = (
    parameters: ScreenParameter[],
    screen: FunnelScreen
  ) => {
    const result = {} as Record<string, any>;
    parameters.forEach((parameter) => {
      if (parameter.type === "array") {
        let tempArray = [] as Record<string, any>[];
        if (parameter.elements) {
          parameter.elements.forEach((element) => {
            if (element.type === "object") {
              const elementParameters = {} as Record<string, any>;
              element.parameters?.forEach((parameter) => {
                elementParameters[parameter.name] = parameter.defaultValue;
              });
              tempArray = tempArray.concat(elementParameters);
            }
          });
        }
        result[parameter.name] = tempArray;
      } else {
        if (parameter.name === "parameterName") {
          result[parameter.name] = screen.name.toLowerCase().replace(/ /g, "_");
          return;
        }
        result[parameter.name] = parameter.defaultValue;
      }
    });
    return result;
  };
  const syncFunnelScreen = async (
    user: User,
    projectId: string,
    funnelId: string,
    screenId: string
  ) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const screenRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "funnels",
        funnelId,
        "screens",
        screenId
      );
      onSnapshot(screenRef, (snapshot) => {
        const screen = { id: snapshot.id, ...snapshot.data() } as FunnelScreen;
        if (screen.type) {
          const screenTemplateRef = doc(db, "screens", screen.type);
          onSnapshot(screenTemplateRef, (snapshot) => {
            const screenTemplate = {
              id: snapshot.id,
              ...snapshot.data()
            } as Screen;
            setScreen({ ...screen, template: screenTemplate } as FunnelScreen);
            setScreenName(screen.name);
            console.log("Screen params", screen.parameters);
            if (!screen.parameters) {
              setParameters(
                collectParameters(screenTemplate.parameters, screen)
              );
            } else {
              setParameters(screen.parameters);
            }
            setLoading(false);
          });
        }
      });
    } catch (error) {
      setScreen(undefined);
      setLoading(false);
    }
  };

  const syncFunnel = (user: User, projectId: string, funnelId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const funnelRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "funnels",
        funnelId
      );
      onSnapshot(funnelRef, (snapshot) => {
        const funnel = { id: snapshot.id, ...snapshot.data() };
        setFunnel(funnel as Funnel);
        setLoading(false);
      });
    } catch (error) {
      setFunnel(undefined);
      setLoading(false);
    }
  };

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId && funnelId && screenId) {
        setUser(user);
        syncProject(user, projectId);
        syncFunnel(user, projectId, funnelId);
        syncFunnelScreen(user, projectId, funnelId, screenId);
      }
    });
  }, [user, projectId, funnelId, screenId]);

  const [parameters, setParameters] = useState<Record<string, any>>({});
  console.log("Screen", screen);

  const handleSaveParameters = async () => {
    if (user && projectId && funnelId && screenId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            funnelId,
            "screens",
            screenId
          ),
          {
            parameters: parameters
          }
        );
        notistack.enqueueSnackbar("Screen saved!", { variant: "success" });
        syncFunnelScreen(user, projectId, funnelId, screenId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const saveScreenName = async () => {
    if (user && projectId && funnelId && screenId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            funnelId,
            "screens",
            screenId
          ),
          {
            name: screenName
          }
        );

        handleMenuClose();
        handleCloseRenameDialog();
        notistack.enqueueSnackbar("Screen saved!", { variant: "success" });
        syncFunnelScreen(user, projectId, funnelId, screenId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemoveImage = useCallback(
    (parameter: ScreenParameter) => {
      // const storage = getStorage();
      // const name =
      //   "/" +
      //   decodeURIComponent(
      //     parameters[parameter.name].split("/o/")[1].split("?alt=media")[0]
      //   );
      // Create a reference to the file to delete
      // const imageRef = ref(storage, name);
      if (window.confirm("Are you sure you want to remove the image?")) {
        // // Delete the file
        // deleteObject(imageRef)
        // .then(() => {
        setParameters((state) =>
          Object.keys(state)
            .filter((key) => key !== parameter.name)
            .reduce((obj, key) => {
              (obj as Record<string, string>)[key] = state[key];
              return obj;
            }, {})
        );

        notistack.enqueueSnackbar("File has been removed successfully", {
          variant: "success"
        });
        // })
        // .catch((error) => {
        //   console.error("Error", error);
        //   notistack.enqueueSnackbar("Error while removing the image!", {
        //     variant: "error",
        //   });
        //   setParameters((state) =>
        //     Object.keys(state)
        //       .filter((key) => key !== parameter.name)
        //       .reduce((obj, key) => {
        //         (obj as Record<string, string>)[key] = state[key];
        //         return obj;
        //       }, {})
        //   );
        // });
      }
    },
    [notistack]
  );

  const [imageProgressPercent, setImageProgressPercent] = useState(0);
  const handleUploadClick = (
    event: any,
    parameter: ScreenParameter,
    elementIndex?: number,
    nestedParameter?: ScreenParameter
  ) => {
    console.log("Handle upload", elementIndex, nestedParameter);
    const storage = getStorage();
    const file = event.target.files[0];
    if (!file) return;
    if (user) {
      const storageRef = ref(
        storage,
        `${user.uid}/${projectId}/${funnelId}/${screenId}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setImageProgressPercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (elementIndex !== undefined && nestedParameter !== undefined) {
              setParameters((parameters) => ({
                ...parameters,
                [parameter.name]: parameters[parameter.name].map(
                  (el: Record<string, any>, index: number) => {
                    if (elementIndex === index) {
                      return {
                        ...parameters[parameter.name][elementIndex],
                        [nestedParameter.name]: downloadURL
                      };
                    }
                    return el;
                  }
                )
              }));
            } else {
              setParameters((parameters) => ({
                ...parameters,
                [parameter.name]: downloadURL
              }));
            }
            // setImageProgressPercent(0);
          });
        }
      );
    }
  };

  const fetchFunnelScreens = async () => {
    const db = getFirestore();
    if (user && projectId && funnelId && screenId) {
      try {
        setLoading(true);
        const funnelScreensRef = collection(
          db,
          "projects",
          user.uid,
          "projects",
          projectId,
          "funnels",
          funnelId,
          "screens"
        );
        const q = query(funnelScreensRef, orderBy("index"));
        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        // onSnapshot(funnelScreensRef, (snapshot) => {
        const funnelScreens = querySnapshot.docs.map((document) => {
          const screen = {
            id: document.id,
            ...document.data()
          } as FunnelScreen;
          return screen;
        });
        return funnelScreens;
      } catch (error) {
        setLoading(false);
        return [];
      }
    }
    return [];
  };

  const handleNavigatePreviousScreen = async () => {
    const funnelScreens = await fetchFunnelScreens();
    const currentIndex = funnelScreens.map((s) => s.id).indexOf(screenId);
    const previousIndex = Math.max(0, currentIndex - 1);
    navigate("./../../screens/" + funnelScreens[previousIndex].id);
  };

  const handleNavigateNextScreen = async () => {
    const funnelScreens = await fetchFunnelScreens();
    const currentIndex = funnelScreens.map((s) => s.id).indexOf(screenId);
    const nextIndex = Math.min(funnelScreens.length - 1, currentIndex + 1);
    const nextId = funnelScreens[nextIndex].id;
    navigate("./../../screens/" + nextId);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function duplicateDocument(
    sourceDocumentPath: string,
    sourceDocumentId: string,
    targetDocumentPath: string
  ) {
    const db = getFirestore();
    try {
      // Fetch the source document
      console.log(
        "Duplicate from " + sourceDocumentPath + " to " + targetDocumentPath
      );
      const sourceDocumentSnapshot = await getDoc(doc(db, sourceDocumentPath));

      if (!sourceDocumentSnapshot.exists) {
        console.error(`Source document ${sourceDocumentId} does not exist.`);
        return;
      }

      const sourceData = sourceDocumentSnapshot.data();

      // Create a new document in the target collection
      await setDoc(doc(db, targetDocumentPath), {
        ...sourceData,
        index: sourceData?.index + 1,
        name: sourceData?.name + " Copy"
      });

      console.log("Document duplicated successfully.");
    } catch (error) {
      console.error("Error duplicating document:", error);
    }
  }

  const duplicateScreen = useCallback(
    async (screenId: string) => {
      if (user && projectId) {
        try {
          setLoading(true);
          const newId = nanoid();
          duplicateDocument(
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/funnels/" +
              funnelId +
              "/screens/" +
              screenId,
            screenId,
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/funnels/" +
              funnelId +
              "/screens/" +
              newId
          );
          handleMenuClose();
          navigate("./../../screens/" + newId);
          // syncFunnels(user, projectId);
        } catch (error) {
          console.error("Error duplicating screen:", error);
          setLoading(false);
        }
      }
    },
    [user]
  );

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [screenName, setScreenName] = useState<string | undefined>(
    screen?.name
  );
  const handleClickOpenRenameDialog = () => {
    setOpenRenameDialog(true);
  };

  const handleCloseRenameDialog = () => {
    setOpenRenameDialog(false);
    setScreenName(undefined);
  };

  return (
    <Box
      sx={{
        display: "flex"
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          backgroundColor: "rgba(217, 217, 217, 0.25)",
          padding: "48px",
          flexGrow: "auto",
          minHeight: "calc(100vh - 90px)"
        }}>
        <Typography variant="h1">
          <Box component="span" sx={{ cursor: "pointer" }}>
            <IconButton
              sx={{ marginLeft: "-40px" }}
              onClick={() => navigate("./../../")}>
              <ChevronLeft />
            </IconButton>
            Edit data
          </Box>
          <Box sx={{ marginTop: "24px" }}>
            {screen?.template?.hint && (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "normal",
                  color: "#666",
                  marginBottom: "12px"
                }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: screen?.template.hint
                      .replace(
                        /{SCREEN_NAME}/g,
                        screen.name.toLowerCase().replace(/ /g, "_")
                      )
                      .replace(
                        /{PARAMETER_NAME}/g,
                        screen.parameters?.["parameterName"] ||
                          screen.name.toLowerCase().replace(/ /g, "_")
                      )
                  }}></span>
              </Typography>
            )}
            {screen?.template &&
              screen?.template?.parameters &&
              screen?.template?.parameters.map((parameter, parameterIndex) => {
                switch (parameter.type) {
                  case "line":
                    return (
                      <Box
                        key={parameterIndex}
                        sx={{
                          height: "1px",
                          backgroundColor: "#b8d8e8",
                          margin: "24px"
                        }}
                      />
                    );
                  case "text":
                  case "number":
                  case "textArea":
                    return (
                      <Box key={parameterIndex}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "normal",
                            color: "#666",
                            marginBottom: "12px"
                          }}>
                          {parameter.label}
                        </Typography>
                        {parameter.hint && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "normal",
                              color: "#666",
                              marginBottom: "12px"
                            }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: parameter.hint
                                  .replace(
                                    /{SCREEN_NAME}/g,
                                    screen.name.toLowerCase().replace(/ /g, "_")
                                  )
                                  .replace(
                                    /{PARAMETER_NAME}/g,
                                    screen.parameters?.["parameterName"] ||
                                      screen.name
                                        .toLowerCase()
                                        .replace(/ /g, "_")
                                  )
                              }}></span>
                          </Typography>
                        )}
                        <TextField
                          multiline
                          fullWidth
                          sx={{ marginBottom: "24px" }}
                          name={parameter.name}
                          value={parameters[parameter.name]}
                          placeholder={parameter.placeHolder}
                          onChange={(e) => {
                            setParameters((parameters) => ({
                              ...parameters,
                              [parameter.name]: e.target.value
                            }));
                          }}
                        />
                      </Box>
                    );
                  case "dropDown":
                    return (
                      <>
                        <div
                          style={{
                            marginTop: "12px",
                            display: "flex",
                            flexDirection: "row",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "normal",
                              color: "#666",
                              marginBottom: "12px",
                              marginRight: "20px"
                            }}>
                            {parameter.label}
                          </Typography>
                          <Select
                            sx={{
                              height: "100%",
                              width: "auto",
                              minWidth: "150px",
                              padding: "20px 20px 20px 10px"
                            }}
                            value={parameters[parameter.name]}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setParameters((parameters) => ({
                                ...parameters,
                                [parameter.name]: selectedValue,
                                variableName: selectedValue
                              }));
                            }}>
                            {parameter.options?.map((option) => {
                              console.log("Parameter:", parameter, parameters);
                              return (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </>
                    );
                  case "image":
                    return (
                      <>
                        {parameter.hint && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "normal",
                              color: "#666",
                              marginBottom: "12px"
                            }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: parameter.hint
                                  .replace(
                                    /{SCREEN_NAME}/g,
                                    screen.name.toLowerCase().replace(/ /g, "_")
                                  )
                                  .replace(
                                    /{PARAMETER_NAME}/g,
                                    screen.parameters?.["parameterName"] ||
                                      screen.name
                                        .toLowerCase()
                                        .replace(/ /g, "_")
                                  )
                              }}></span>
                          </Typography>
                        )}
                        <Box
                          key={parameterIndex}
                          sx={{
                            minHeight: "1px",
                            marginTop: "40px",
                            marginBottom: "45px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "relative"
                          }}>
                          {imageProgressPercent > 0 &&
                            imageProgressPercent < 100 && (
                              <LinearProgress
                                key={parameterIndex}
                                variant="determinate"
                                value={imageProgressPercent}
                                sx={{
                                  position: "absolute",
                                  marginBottom: "-63px",
                                  left: "0",
                                  right: "0"
                                }}
                              />
                            )}
                          <TextField
                            sx={{
                              position: "absolute",
                              left: "0",
                              right: parameters[parameter.name] ? "60px" : "0"
                            }}
                            placeholder={parameter.name}
                            value={
                              parameters[parameter.name]
                                ? parameters[parameter.name]
                                : ""
                            }
                            disabled></TextField>
                          <label
                            htmlFor={"contained-button-file-" + parameterIndex}
                            style={{
                              position: "absolute",
                              right: parameters[parameter.name]
                                ? "70px"
                                : "10px"
                            }}>
                            <Button
                              variant="contained"
                              component="span"
                              sx={{ height: "20px" }}>
                              Select Image
                              <input
                                accept="image/*"
                                id={"contained-button-file-" + parameterIndex}
                                style={{
                                  display: "none"
                                }}
                                multiple
                                type="file"
                                onChange={(event) => {
                                  handleUploadClick(
                                    event,
                                    parameter,
                                    undefined,
                                    undefined
                                  );
                                }}
                              />
                            </Button>
                          </label>
                          {parameters[parameter.name] && (
                            <img
                              src={parameters[parameter.name]}
                              style={{
                                width: "50px",
                                position: "absolute",
                                right: "0",
                                borderRadius: "8px"
                              }}
                            />
                          )}
                          {parameters[parameter.name] && (
                            <IconButton
                              onClick={() => handleRemoveImage(parameter)}
                              sx={{
                                position: "absolute",
                                right: "-40px"
                              }}>
                              <DeleteOutline />
                            </IconButton>
                          )}
                        </Box>
                      </>
                    );
                  case "switch":
                    return (
                      <Box key={parameterIndex}>
                        <FormControl component="fieldset" variant="standard">
                          {/* <FormLabel component="legend">
                            {parameter.label}
                          </FormLabel> */}

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={parameters[parameter.name]}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setParameters((parameters) => ({
                                      ...parameters,
                                      [parameter.name]: event.target.checked
                                    }));
                                  }}
                                  name={parameter.name}
                                />
                              }
                              label={parameter.label}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    );
                  case "array":
                    console.log("Array parameters", parameters[parameter.name]);
                    return (
                      <Box key={parameterIndex} sx={{ marginBottom: "24px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "normal",
                            marginBottom: "12px"
                          }}>
                          {parameter.label}
                        </Typography>
                        {parameter.hint && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "normal",
                              color: "#666",
                              marginBottom: "12px"
                            }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: parameter.hint
                                  .replace(
                                    /{SCREEN_NAME}/g,
                                    screen.name.toLowerCase().replace(/ /g, "_")
                                  )
                                  .replace(
                                    /{PARAMETER_NAME}/g,
                                    screen.parameters?.["parameterName"] ||
                                      screen.name
                                        .toLowerCase()
                                        .replace(/ /g, "_")
                                  )
                              }}></span>
                          </Typography>
                        )}
                        {parameters[parameter.name] &&
                          parameters[parameter.name].map(
                            (parameterElement: any, elementIndex: number) => {
                              const element = parameter.elements?.[0];
                              console.log(
                                "Paramatere",
                                parameterElement,
                                elementIndex
                              );
                              return (
                                element && (
                                  <Box
                                    key={elementIndex}
                                    sx={{ position: "relative" }}>
                                    <IconButton
                                      size="small"
                                      sx={{
                                        position: "absolute",
                                        right: "0",
                                        top: "-5px"
                                      }}
                                      onClick={() => {
                                        const newParameters = {
                                          ...parameters,
                                          [parameter.name]: parameters[
                                            parameter.name
                                          ].filter(
                                            (
                                              el: Record<string, any>,
                                              index: number
                                            ) => {
                                              console.log("Remove el", el);
                                              return index !== elementIndex;
                                            }
                                          )
                                        };
                                        console.log(
                                          "New parameters",
                                          newParameters
                                        );
                                        setParameters(newParameters);
                                      }}>
                                      <Delete />
                                    </IconButton>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        marginBottom: "12px"
                                      }}>
                                      Option {elementIndex + 1}
                                    </Typography>
                                    {element.parameters &&
                                      element.parameters.map(
                                        (
                                          nestedParameter,
                                          nestedParameterIndex
                                        ) => {
                                          switch (nestedParameter.type) {
                                            case "image":
                                              return (
                                                <Box
                                                  key={nestedParameterIndex}
                                                  sx={{
                                                    minHeight: "1px",
                                                    marginTop: "40px",
                                                    marginBottom: "45px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                      "space-between",
                                                    position: "relative"
                                                  }}>
                                                  <TextField
                                                    sx={{
                                                      position: "absolute",
                                                      left: "0",
                                                      right: parameters[
                                                        parameter.name
                                                      ][elementIndex][
                                                        nestedParameter.name
                                                      ]
                                                        ? "60px"
                                                        : "0px"
                                                    }}
                                                    placeholder={
                                                      nestedParameter.name
                                                    }
                                                    value={
                                                      parameters[
                                                        parameter.name
                                                      ][elementIndex][
                                                        nestedParameter.name
                                                      ]
                                                        ? parameters[
                                                            parameter.name
                                                          ][elementIndex][
                                                            nestedParameter.name
                                                          ]
                                                        : ""
                                                    }
                                                    disabled></TextField>
                                                  <label
                                                    htmlFor={
                                                      "contained-button-file-nested-" +
                                                      elementIndex
                                                    }
                                                    style={{
                                                      position: "absolute",
                                                      right: parameters[
                                                        parameter.name
                                                      ][elementIndex][
                                                        nestedParameter.name
                                                      ]
                                                        ? "70px"
                                                        : "10px"
                                                    }}>
                                                    <Button
                                                      variant="contained"
                                                      component="span"
                                                      sx={{ height: "20px" }}>
                                                      Select Image
                                                      <input
                                                        accept="image/*"
                                                        id={
                                                          "contained-button-file-nested-" +
                                                          elementIndex
                                                        }
                                                        style={{
                                                          display: "none"
                                                        }}
                                                        multiple
                                                        type="file"
                                                        onChange={(event) => {
                                                          console.log(
                                                            "elementIndex",
                                                            elementIndex
                                                          );
                                                          handleUploadClick(
                                                            event,
                                                            parameter,
                                                            elementIndex,
                                                            nestedParameter
                                                          );
                                                        }}
                                                      />
                                                    </Button>
                                                  </label>
                                                  {parameters[parameter.name][
                                                    elementIndex
                                                  ][nestedParameter.name] && (
                                                    <img
                                                      src={
                                                        parameters[
                                                          parameter.name
                                                        ][elementIndex][
                                                          nestedParameter.name
                                                        ]
                                                      }
                                                      style={{
                                                        width: "50px",
                                                        position: "absolute",
                                                        right: "0",
                                                        borderRadius: "8px"
                                                      }}
                                                    />
                                                  )}
                                                </Box>
                                              );
                                            default:
                                              return (
                                                <TextField
                                                  key={nestedParameterIndex}
                                                  fullWidth
                                                  sx={{ marginBottom: "24px" }}
                                                  value={
                                                    parameters[parameter.name][
                                                      elementIndex
                                                    ][nestedParameter.name]
                                                  }
                                                  name={parameter.name}
                                                  placeholder={
                                                    nestedParameter.placeHolder
                                                  }
                                                  onChange={(e) => {
                                                    setParameters(
                                                      (parameters) => ({
                                                        ...parameters,
                                                        [parameter.name]:
                                                          parameters[
                                                            parameter.name
                                                          ].map(
                                                            (
                                                              el: Record<
                                                                string,
                                                                any
                                                              >,
                                                              index: number
                                                            ) => {
                                                              if (
                                                                elementIndex ===
                                                                index
                                                              ) {
                                                                return {
                                                                  ...parameters[
                                                                    parameter
                                                                      .name
                                                                  ][
                                                                    elementIndex
                                                                  ],
                                                                  [nestedParameter.name]:
                                                                    e.target
                                                                      .value
                                                                };
                                                              }
                                                              return el;
                                                            }
                                                          )
                                                      })
                                                    );
                                                  }}
                                                />
                                              );
                                          }
                                        }
                                      )}
                                  </Box>
                                )
                              );
                            }
                          )}
                        <Button
                          sx={{ height: "24px", fontSize: "12px" }}
                          onClick={() => {
                            console.log(
                              "Click add element",
                              parameter.name,
                              parameters[parameter.name]
                            );

                            // Create new element based on the first element if it exists, or create empty object
                            const newElement = parameters[parameter.name]?.[0]
                              ? Object.keys(
                                  parameters[parameter.name][0]
                                ).reduce(
                                  (acc, key) => ((acc[key] = ""), acc),
                                  {} as Record<string, any>
                                )
                              : { title: "" }; // Default structure if no existing elements

                            const newParameters = {
                              ...parameters,
                              [parameter.name]: [
                                ...(parameters[parameter.name] || []), // Handle undefined case
                                newElement
                              ]
                            };

                            setParameters(newParameters);
                            if (
                              screen &&
                              screen?.template &&
                              screen?.template?.parameters
                            ) {
                              // @ts-ignore
                              setScreen((screen) => ({
                                ...screen,
                                template: {
                                  ...screen?.template,
                                  parameters: screen?.template?.parameters.map(
                                    (parameter, pIndex) => {
                                      if (pIndex === parameterIndex) {
                                        return {
                                          ...parameter,
                                          elements: [
                                            ...(parameter.elements || []),
                                            {
                                              ...(parameter.elements || [])[0]
                                            }
                                          ]
                                        };
                                      }
                                      return parameter;
                                    }
                                  )
                                }
                              }));
                            }
                          }}>
                          <Add sx={{ fontSize: "12px" }} /> Add element
                        </Button>
                      </Box>
                    );
                  default:
                    return (
                      <TextField
                        key={parameterIndex}
                        fullWidth
                        sx={{ marginBottom: "24px" }}
                        value={parameter.defaultValue}
                        name={parameter.name}
                        placeholder={parameter.placeHolder}
                        onChange={(e) => {
                          setParameters((parameters) => ({
                            ...parameters,
                            [parameter.name]: e.target.value
                          }));
                        }}
                      />
                    );
                }
              })}
          </Box>
          <Button onClick={handleSaveParameters}>Save</Button>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexGrow: "1", backgroundColor: "#fff" }}>
        <Box
          sx={{
            marginBottom: "64px",
            borderRadius: "12px",
            padding: "48px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative"
          }}>
          <Typography
            variant="h1"
            align="center"
            sx={{ marginBottom: "24px", position: "relative" }}>
            {(screen?.index ?? 0) + 1 + "."} {screen?.name}{" "}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                handleMenuClick(e);
              }}
              sx={{ position: "absolute", right: "-50px", bottom: "-5px" }}>
              <MoreHoriz />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (
                    window.confirm(
                      "Are you sure you want to duplicate the screen " +
                        screen?.name
                    )
                  ) {
                    if (screen && screen.id) {
                      duplicateScreen(screen.id);
                    }
                  }
                }}>
                Duplicate
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickOpenRenameDialog();
                }}>
                Rename
              </MenuItem>
              <Dialog open={openRenameDialog} onClose={handleCloseRenameDialog}>
                <DialogTitle>Rename Screen</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={screenName || ""}
                    onChange={(e) => setScreenName(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      saveScreenName();
                    }}
                    variant="contained">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Menu>
          </Typography>
          {project && screen && (
            <Box sx={{ position: "relative" }}>
              <IconButton
                size="large"
                sx={{ position: "absolute", left: "-70px", bottom: "50%" }}
                onClick={handleNavigatePreviousScreen}>
                <ArrowCircleLeft sx={{ fontSize: 40 }} />
              </IconButton>
              <IconButton
                size="large"
                sx={{ position: "absolute", right: "-70px", bottom: "50%" }}
                onClick={handleNavigateNextScreen}>
                <ArrowCircleRight sx={{ fontSize: 40 }} />
              </IconButton>
              <ScreenPreview
                screen={screen}
                project={project}
                parameters={parameters}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScreenPage;
