import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Switch,
  FormControlLabel,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import ImageUpload from "../../../components/ImageUpload";

export interface ImageBlockProps {
  parameters: {
    title?: string;
    image?: string;
    fill?: string;
    padding?: string;
    variableDependency?: boolean;
    variableName?: string;
    variableValue?: string;
    variableDepenendendImage?: string;
  };
  onChange: (param: string, value: unknown) => void;
  projectId?: string;
}

const ImageBlock: React.FC<ImageBlockProps> = ({
  parameters,
  onChange,
  projectId
}) => {
  const variables = ["gender"];

  const [variableName, setVariableName] = useState(parameters.variableName);

  // Automatically call updateVariableName when variableName changes
  useEffect(() => {
    if (variableName) {
      updateVariableName(variableName);
    }
  }, [variableName]);

  const handleFillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange("fill", event.target.checked ? "cover" : "contain");
  };

  const handleVariableDependencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setVariableName(variables[0]);
    onChange("variableDependency", isChecked);
  };

  const onVariableNameChange = (param: string, value: unknown) => {
    onChange(param, value);
  };

  const updateVariableName = (newVariableName: string) => {
    setVariableName(newVariableName);
    onChange("variableName", newVariableName);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          gap: 2
        }}>
        <TextField
          label="Title"
          sx={{ width: "100%" }}
          value={parameters.title || ""}
          onChange={(e) => onChange("title", e.target.value)}
        />
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={parameters.fill === "cover"}
                onChange={handleFillChange}
              />
            }
            label="Fill Cover"
          />
          <TextField
            label="Padding"
            value={parameters.padding || ""}
            onChange={(e) => onChange("padding", e.target.value)}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={parameters.variableDependency}
                onChange={handleVariableDependencyChange}
              />
            }
            label="Variable Dependency"
          />
          {parameters.variableDependency && (
            <Select
              value={parameters.variableDependency ? variables[0] : ""}
              onChange={(e) =>
                onVariableNameChange("variableName", e.target.value)
              }
              sx={{ height: 48, padding: "15px 15px" }}>
              {variables.map((variable) => (
                <MenuItem key={variable} value={variable}>
                  {variable}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        {parameters.variableDependency && (
          <>
            <TextField
              label="Variable Value"
              value={parameters.variableValue || ""}
              onChange={(e) => onChange("variableValue", e.target.value)}
            />
            <ImageUpload
              title={`When ${parameters.variableName} = ${parameters.variableValue}`}
              key={parameters.variableName}
              label="Image URL"
              value={parameters.variableDepenendendImage || ""}
              onChange={(url) => onChange("variableDepenendendImage", url)}
              projectId={projectId}
            />
          </>
        )}
        <ImageUpload
          title={
            parameters.variableDependency
              ? "Fallback Image (any other variable value)"
              : ""
          }
          key="img"
          label="Image URL"
          value={parameters.image || ""}
          onChange={(url) => onChange("image", url)}
          projectId={projectId}
        />
      </Box>
    </Paper>
  );
};

export default ImageBlock;
