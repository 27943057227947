import { parseVariables } from "../../../../utils/parseStrings";
import {
  QuizOptionGroup,
  useQuizOptionGroup
} from "../../../QuizLayout/components/QuizOptionGroup";

import { Box, Typography } from "@mui/material";
import { Progress } from "../../../QuizLayout/components/Progress";
import ActionsContainer from "../../../ActionsContainer";

interface ComponentProps {
  screenName: string;
  title?: string;
  subtitle?: string;
  image?: string;
  options?: { text: string; value?: string; emoji?: string; image?: string }[];
  multichoice?: boolean | string;
  cta?: string;
  continueButtonEnabled?: boolean;
  roundBorders?: boolean;
  fill?: boolean;
  notOption?: string;
  yesOption?: string;
}

const Component = (props: ComponentProps) => {
  const {
    screenName,
    title,
    subtitle,
    image,
    options,
    multichoice,
    cta = "Continue",
    continueButtonEnabled = false,
    roundBorders = false,
    fill = true,
    notOption = "Not at all",
    yesOption = "Totally"
  } = props;

  const multiSelect = multichoice === true || multichoice === "true";
  const parameterName = screenName.toLowerCase().replace(/ /g, "_");
  const quizOptionGroup = useQuizOptionGroup(parameterName, multiSelect);

  const handleSelectOption = (value: string) => {
    quizOptionGroup.selectOption(value);
    if (!continueButtonEnabled && !multiSelect) {
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="title_01"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{
            marginBottom: subtitle ? "12px" : !image ? "48px" : "12px"
          }}></Typography>
      )}
      {subtitle && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle)
          }}
          sx={{ marginBottom: !image ? "48px" : "0px" }}
          align="center"></Typography>
      )}
      {image && (
        <Box
          sx={{
            width: "100%",
            borderRadius: roundBorders ? "36px" : "0px",
            overflow: "hidden",
            flexGrow: "1",
            maxHeight: "325px",
            backgroundImage: "url(" + image + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: fill ? "cover" : "contain",
            backgroundPosition: fill ? "center" : "top center",
            marginBottom: "24px"
          }}></Box>
      )}
      <Box>
        {options && options.length > 0 && (
          <QuizOptionGroup
            options={options}
            {...quizOptionGroup}
            selectOption={handleSelectOption}
            multiSelect={multiSelect}
            notOption={notOption}
            yesOption={yesOption}
          />
        )}
      </Box>
      {(continueButtonEnabled || multiSelect) && <ActionsContainer cta={cta} />}
    </>
  );
};

export default Component;
