import React from "react";
import { Box, TextField, Paper } from "@mui/material";
import { BlockParameters } from "../Paywall";

export interface NoteBlockProps {
  parameters: BlockParameters;
  onChange: (param: string, value: unknown) => void;
}

const NoteBlock: React.FC<NoteBlockProps> = ({ parameters, onChange }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Text"
          value={parameters.text}
          onChange={(e) => onChange("text", e.target.value)}
        />
      </Box>
    </Paper>
  );
};

export default NoteBlock;
