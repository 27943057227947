import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { Project } from "../../types";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  TextareaAutosize,
  Typography
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import ProjectHostingFiles from "../../components/ProjectHostingFiles";

const PARAMETERS = [
  { name: "pp", title: "Privacy policy" },
  { name: "mb", title: "Money-back policy" },
  { name: "tc", title: "Terms and Conditions" }
];

const THEME_PARAMETERS = [{ name: "color", title: "Main color" }];

const SEPARATE_PARAMETERS = [
  // { name: "logo", title: "Logo" },
  // { name: "logo_secondary", title: "Rating header" },
  { name: "NEXT_PUBLIC_STRIPE_KEY", title: "Stripe" },
  { name: "NEXT_PUBLIC_STRIPE_SECRET_KEY", title: "Stripe Secret key" },
  { name: "NEXT_PUBLIC_AMPLITUDE_API_KEY", title: "Amplitude API Key 💛" },
  { name: "NEXT_PUBLIC_META_PIXEL", title: "Meta Pixel" },
  { name: "NEXT_PUBLIC_GA4_KEY", title: "Google Analytics" },
  { name: "NEXT_PUBLIC_PAYPRO_CHECKOUTTEMPLATE", title: "PayPro Template ID" },
  {
    name: "NEXT_PUBLIC_PAYPRO_VENDORACCOUNTID",
    title: "PayPro VendorAccountId"
  },
  {
    name: "NEXT_PUBLIC_PAYPRO_API_SECRET_KEY",
    title: "PayPro API Secret Key"
  },
  { name: "NEXT_PUBLIC_PAYWALL_REDIRECT", title: "Paywall Redirect" },
  { name: "NEXT_PUBLIC_THANK_YOU_REDIRECT", title: "Thank You Page Redirect" },
  { name: "NEXT_PUBLIC_CLOUD_FUNCTIONS", title: "Cloud Functions URL" }
];

const ProjectSettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const notistack = useSnackbar();
  const { projectId } = params;
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<Record<string, any>>({});
  console.log("Loading", loading);
  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() } as Project;
        setProject(project);
        if (project.parameters) {
          setParameters(project.parameters);
        }
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId) {
        setUser(user);
        syncProject(user, projectId);
      }
    });
  }, []);

  const handleSaveParameters = async () => {
    if (user && projectId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await updateDoc(doc(db, "projects", user.uid, "projects", projectId), {
          parameters: parameters,
          name: project?.name
        });
        notistack.enqueueSnackbar("Project saved!", { variant: "success" });
        syncProject(user, projectId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  // const [imageProgressPercent, setImageProgressPercent] = useState(0);
  const [imageLoading, setImageLoading] = useState<string | undefined>();
  const handleUploadClick = (event: any, parameter: string) => {
    const storage = getStorage();
    const file = event.target.files[0];
    if (!file) return;
    if (user) {
      const storageRef = ref(storage, `${user.uid}/${projectId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      setImageLoading(parameter);
      uploadTask.on(
        "state_changed",
        () => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setImageProgressPercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("Ready");
            setParameters((parameters) => ({
              ...parameters,
              [parameter]: downloadURL
            }));
            setImageLoading(undefined);
            // setImageProgressPercent(0);
          });
        }
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Typography variant="h1">
        <Box
          component="span"
          sx={{ color: "#666", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}>
          Projects {">"}
        </Box>
        <Box
          component="span"
          sx={{ color: "#282828", cursor: "pointer" }}
          onClick={() => navigate("./../")}>
          {" "}
          {project?.name}
        </Box>
        <IconButton>
          <Settings />
        </IconButton>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          marginTop: "24px",
          marginBottom: "64px",
          maxWidth: "50%"
        }}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Project name
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "24px" }}
            name="name"
            value={project?.name || ""}
            onChange={(e) => {
              setProject((project) => ({
                ...project,
                name: e.target.value
              }));
            }}
          />
        </Box>

        <Box sx={{ position: "relative", width: "100%", marginBottom: "80px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Logo
          </Typography>
          <TextField
            sx={{
              position: "absolute",
              left: "0",
              right: parameters?.logo ? "60px" : "0"
            }}
            placeholder={"Logo"}
            value={parameters?.logo ? parameters?.logo : ""}
            disabled></TextField>
          <label
            htmlFor={"contained-button-file-logo"}
            style={{
              position: "absolute",
              right: parameters?.logo ? "70px" : "10px",
              top: "40px"
            }}>
            <Button
              variant="contained"
              component="span"
              sx={{ height: "20px" }}>
              {imageLoading && imageLoading === "logo" ? (
                <CircularProgress size={12} sx={{ color: "#fff" }} />
              ) : (
                <>
                  Select Image
                  <input
                    accept="image/*"
                    id={"contained-button-file-logo"}
                    style={{
                      display: "none"
                    }}
                    multiple
                    type="file"
                    onChange={(event) => {
                      handleUploadClick(event, "logo");
                    }}
                  />
                </>
              )}
            </Button>
          </label>
          {parameters?.logo && (
            <img
              src={parameters?.logo}
              style={{
                width: "50px",
                position: "absolute",
                right: "0",
                borderRadius: "8px"
              }}
            />
          )}
        </Box>

        <Box sx={{ position: "relative", width: "100%", marginBottom: "80px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Rating header
          </Typography>
          <TextField
            sx={{
              position: "absolute",
              left: "0",
              right: parameters?.logo_secondary ? "60px" : "0"
            }}
            placeholder={"Logo"}
            value={parameters?.logo_secondary ? parameters?.logo_secondary : ""}
            disabled></TextField>
          <label
            htmlFor={"file-logo-secondary"}
            style={{
              position: "absolute",
              right: parameters?.logo_secondary ? "70px" : "10px",
              top: "40px"
            }}>
            <Button
              variant="contained"
              component="span"
              sx={{ height: "20px" }}>
              {imageLoading && imageLoading === "logo_secondary" ? (
                <CircularProgress size={12} sx={{ color: "#fff" }} />
              ) : (
                <>
                  Select Image
                  <input
                    accept="image/*"
                    id={"file-logo-secondary"}
                    style={{
                      display: "none"
                    }}
                    multiple
                    type="file"
                    onChange={(event) => {
                      handleUploadClick(event, "logo_secondary");
                    }}
                  />
                </>
              )}
            </Button>
          </label>
          {parameters?.logo_secondary && (
            <img
              src={parameters?.logo_secondary}
              style={{
                width: "50px",
                position: "absolute",
                right: "0",
                borderRadius: "8px"
              }}
            />
          )}
        </Box>

        <Grid container spacing={1}>
          {PARAMETERS.map((parameter) => (
            <Grid item xs={4}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "normal",
                    color: "#666",
                    marginBottom: "12px"
                  }}>
                  {parameter.title}
                </Typography>
                <TextField
                  fullWidth
                  sx={{ marginBottom: "24px" }}
                  name={parameter.name}
                  value={parameters[parameter.name]}
                  onChange={(e) => {
                    setParameters((parameters) => ({
                      ...parameters,
                      [parameter.name]: e.target.value
                    }));
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4}>
          {THEME_PARAMETERS.map((parameter) => (
            <Grid item>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "normal",
                    color: "#666",
                    marginBottom: "12px"
                  }}>
                  {parameter.title}
                </Typography>
                <TextField
                  fullWidth
                  sx={{ marginBottom: "24px" }}
                  name={parameter.name}
                  value={parameters[parameter.name]}
                  onChange={(e) => {
                    setParameters((parameters) => ({
                      ...parameters,
                      [parameter.name]: e.target.value
                    }));
                  }}
                />
              </Box>
            </Grid>
          ))}
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
              }}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Dark theme</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={parameters.dark_theme}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setParameters((parameters) => ({
                            ...parameters,
                            ["dark_theme"]: event.target.checked
                          }));
                        }}
                        name="dark_theme"
                      />
                    }
                    label=""
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {SEPARATE_PARAMETERS.map((parameter) => (
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "normal",
                    color: "#666",
                    marginBottom: "12px"
                  }}>
                  {parameter.title}
                </Typography>
                <TextField
                  fullWidth
                  sx={{ marginBottom: "24px" }}
                  name={parameter.name}
                  value={parameters[parameter.name]}
                  onChange={(e) => {
                    setParameters((parameters) => ({
                      ...parameters,
                      [parameter.name]: e.target.value
                    }));
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Functions config
          </Typography>
          <TextareaAutosize
            minRows={10}
            value={JSON.stringify(parameters["functions_config"], undefined, 2)}
            name="functions_config"
            onChange={(e) => {
              setParameters((parameters) => ({
                ...parameters,
                functions_config: JSON.parse(e.target.value || "")
              }));
            }}
            style={{ maxWidth: "100%", width: "100%" }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Google Firebase project name
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "24px" }}
            name="firebase_project"
            value={parameters["firebase_project"]}
            onChange={(e) => {
              setParameters((parameters) => ({
                ...parameters,
                firebase_project: e.target.value
              }));
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Google Firebase Token
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "24px" }}
            name="firebase_token"
            value={parameters["firebase_token"]}
            onChange={(e) => {
              setParameters((parameters) => ({
                ...parameters,
                firebase_token: e.target.value
              }));
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "normal",
              color: "#666",
              marginBottom: "12px"
            }}>
            Webhook URL
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "24px" }}
            name="webhook_url"
            value={parameters["webhook_url"]}
            onChange={(e) => {
              setParameters((parameters) => ({
                ...parameters,
                webhook_url: e.target.value
              }));
            }}
          />
        </Box>
        <ProjectHostingFiles />
        <ProjectHostingFiles directory="/.well-known/" />
      </Box>

      <Button onClick={handleSaveParameters}>Save</Button>
    </Box>
  );
};

export default ProjectSettings;
