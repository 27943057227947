import { Box, Button, TextField, Typography } from "@mui/material";

import { parseVariables } from "../../../../utils/parseStrings";
import s from "./NameV1.module.css";
import { Progress } from "../../../QuizLayout/components/Progress";
import Highlight from "../../../Highlight";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  hint?: string;
  options?: { text: string; image: string }[];
  cta?: string;
  screenName?: string;
}

const Component = (props: ComponentProps) => {
  const { title, placeholder, hint, screenName, cta } = props;

  return (
    <div id="inputContainer" key={screenName}>
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="title_01"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginBottom: "12px" }}></Typography>
      )}

      <Box>
        <div className={s.inputWrapper}>
          <TextField
            autoFocus={false}
            id="yourAge"
            type="tel"
            fullWidth
            // pattern="[0-9]*"
            sx={{
              backgroundColor: "transparent",
              borderRadius: "0",
              input: {
                fontSize: "28px",
                textAlign: "center"
              },
              fieldset: {
                border: "none",
                borderRadius: "0",
                borderBottom: "1px solid rgba(249, 249, 249, 0.94)"
              }
            }}
            // variant="primary2-big"
            value={""}
            // onChange={handleValueChange}
            onFocus={() => {
              const body = window.document.getElementsByTagName("body")[0];
              body.style.overflow = "hidden";
              window.scrollTo(0, 0);
              document.body.scrollTop = 0;
            }}
            onBlur={() => {
              const body = window.document.getElementsByTagName("body")[0];
              body.style.overflow = "initial";
            }}
            placeholder={placeholder}
          />
        </div>
      </Box>

      <Highlight>
        {hint && <div dangerouslySetInnerHTML={{ __html: hint }}></div>}
      </Highlight>

      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginTop: "24px" }}>
        {cta}
      </Button>
    </div>
  );
};

export default Component;
